import { useObserver } from 'mobx-react';
import { addCommas, percent } from '@common/module/replaceNumber';
import st from './AfterFinalCheck.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import SignleQuestionHeader from '@commonComponents/SingleQuestionHeader/SingleQuestionHeader';
import NextBtn from '@comComponents/atoms/Button/LastStepBtn/LastStepBtn';
import AfterFinalCheckVm from './AfterFinalCheckVm';
import clsx from 'clsx';
import Loading from '@comComponents/atoms/Loading/Loading';
import BuildingMove from '@pages/common/BuildingMove/BuildingMove';
import { purposeType } from '@pages/Establish/EtcInfo/BusinessPurpose/constant';

function AfterFinalCheck() {
  return useObserver(() => (
    <>
      <SignleQuestionHeader>
        <h1 className="title">
          입력 정보가 <span className="bold">최종적으로 제출</span>되었습니다
        </h1>
        <div className="explain">
          [{AfterFinalCheckVm.submittedAt ? AfterFinalCheckVm.submittedAt : '0000.00.00 00:00'}] 에 제출되었습니다. 정보
          수정을 원하실 경우, 매니저와 채팅상담을 진행해주세요.
        </div>
      </SignleQuestionHeader>
      <div className="mainContent">
        <Question>
          <ContentBox style={AfterFinalCheckVm.isResponse ? null : { height: window.innerHeight }}>
            {AfterFinalCheckVm.isResponse === false ? (
              <Loading />
            ) : (
              <div className={st.content}>
                <div className={st.table}>
                  <div className={st.titleWrapper}>
                    <div className={st.text}>회사정보</div>
                    <BuildingMove />
                  </div>
                  <div className={st.info}>
                    {AfterFinalCheckVm.smartFormType() === '주식회사' ||
                    AfterFinalCheckVm.smartFormType() === '농업회사법인' ||
                    AfterFinalCheckVm.smartFormType() === '어업회사법인' ? (
                      <>
                        <div className={st.row}>
                          <div className={st.dName}>참여 인원</div>
                          <div className={st.data}>{AfterFinalCheckVm.companySize()}</div>
                        </div>
                        {AfterFinalCheckVm.initiatorRole() && (
                          <div className={st.row}>
                            <div className={st.dName}>참여자 역할</div>
                            <div className={st.data}>{AfterFinalCheckVm.initiatorRole()}</div>
                          </div>
                        )}
                      </>
                    ) : null}
                    <div className={st.row}>
                      <div className={st.dName}>한글 회사명</div>
                      <div className={st.data}>{AfterFinalCheckVm.koreanCorpName}</div>
                    </div>
                    {AfterFinalCheckVm.englishCorpName ? (
                      <div className={st.row}>
                        <div className={st.dName}>영문 회사명</div>
                        <div className={st.data}>{AfterFinalCheckVm.englishCorpName}</div>
                      </div>
                    ) : null}
                    <div className={st.row}>
                      <div className={st.dName}>회사 주소</div>
                      <div className={st.data}>{AfterFinalCheckVm.corpAddress}</div>
                    </div>
                    <div className={st.row}>
                      <div className={st.dName}>우편물 받을 주소</div>
                      <div className={st.data}>{AfterFinalCheckVm.deliveryAddress}</div>
                    </div>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.secretBtnArea}>
                    <button className={st.secretBtn} onClick={() => AfterFinalCheckVm.changeSecretMode}>
                      {AfterFinalCheckVm.state.secretMode ? '개인정보 모두 가리기' : '개인정보 모두 보기'}
                    </button>
                  </div>
                  <div className={st.title}>{AfterFinalCheckVm.name()}정보</div>
                  <div className={st.info}>
                    {AfterFinalCheckVm.smartFormType() !== '유한책임회사' && (
                      <div className={st.row}>
                        <div className={st.dName}>
                          {AfterFinalCheckVm.smartFormType() === '유한회사' ? '출자 1좌의 금액' : '1주당 금액'}
                        </div>
                        <div className={st.data}>{addCommas(AfterFinalCheckVm.parValue)}원</div>
                      </div>
                    )}
                    <div className={st.row}>
                      <div className={st.dName}>자본금</div>
                      <div className={st.data}>{addCommas(AfterFinalCheckVm.capital)}원</div>
                    </div>
                    <div className={st.row}>
                      <div className={st.dName}>{AfterFinalCheckVm.name()}정보</div>
                      <div className={st.data}>
                        {AfterFinalCheckVm.shareholders.map((item, idx) => {
                          return (
                            <div className={st.shareholder} key={idx}>
                              <div className={st.shareholderPosition}>
                                {AfterFinalCheckVm.name()}
                                {(AfterFinalCheckVm.smartFormType() === '유한회사' ||
                                  AfterFinalCheckVm.smartFormType() === '유한책임회사') &&
                                  '(=사원)'}
                              </div>
                              <div className={st.shareholderInfo}>
                                <div className={st.person}>
                                  <span>
                                    {item.nationality} {item.koreanName}
                                    {item.englishName ? `(${item.englishName})` : null}{' '}
                                    {item.birth
                                      ? `(${AfterFinalCheckVm.shareholderBirth(idx, AfterFinalCheckVm.state.secretMode)})`
                                      : null}
                                  </span>
                                  <span>{percent(AfterFinalCheckVm.investmentPercent(idx))} 지분</span>
                                  {AfterFinalCheckVm.smartFormType() !== '유한책임회사' && (
                                    <span>{addCommas(AfterFinalCheckVm.stock(idx))}주</span>
                                  )}
                                  <span>투자금 {addCommas(item.investment)}원</span>
                                  {AfterFinalCheckVm.smartFormType() === '농업회사법인' && (
                                    <div className={st.personType}>농업인</div>
                                  )}
                                  {AfterFinalCheckVm.smartFormType() === '어업회사법인' && (
                                    <div className={st.personType}>어업인</div>
                                  )}
                                </div>
                                <div className={st.address}>
                                  {item.address} {item.englishAddress ? `(${item.englishAddress})` : null}
                                </div>
                                {item.representatives && (
                                  <div className={st.representativeInfo}>
                                    {item.shareholderType === 'FOREIGNER_COMPANY' && (
                                      <div className={st.representativeTitle}>대표자 정보</div>
                                    )}
                                    <div className={st.representative}>
                                      {item.representatives?.map((representative, idx) => {
                                        if (item.shareholderType === 'KOREAN_COMPANY') {
                                          return (
                                            <div key={idx}>
                                              {representative.position} {representative.koreanName}
                                            </div>
                                          );
                                        } else if (item.shareholderType === 'FOREIGNER_COMPANY') {
                                          return (
                                            <div key={idx}>
                                              {representative.koreanName + `(${representative.englishName})`}
                                            </div>
                                          );
                                        }
                                      })}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.secretBtnArea}>
                    <button className={st.secretBtn} onClick={() => AfterFinalCheckVm.changeSecretMode}>
                      {AfterFinalCheckVm.state.secretMode ? '개인정보 모두 가리기' : '개인정보 모두 보기'}
                    </button>
                  </div>
                  <div className={st.title}>임원정보</div>
                  <div className={st.info}>
                    <div className={st.row}>
                      <div className={st.dName}>임원정보</div>
                      <div className={st.data}>
                        <div className={st.tdWrap}>
                          {AfterFinalCheckVm.executives.map((item, idx) => {
                            return (
                              <div className={st.executive} key={idx}>
                                <div className={st.executivePosition}>{item.position}</div>
                                <div className={st.executiveInfo}>
                                  <div className={st.person}>
                                    <span>
                                      {item.nationality} {item.koreanName}
                                      {item.englishName ? `(${item.englishName})` : null} (
                                      {AfterFinalCheckVm.executiveBirth(idx, AfterFinalCheckVm.state.secretMode)}){' '}
                                      {item.resignationYn ? (
                                        <span className={st.resignation}>설립후 이어서 사임등기 신청</span>
                                      ) : null}
                                    </span>
                                    {AfterFinalCheckVm.smartFormType() === '농업회사법인' &&
                                      (AfterFinalCheckVm.isShareholder(idx) ||
                                        AfterFinalCheckVm.isFarmerOrFisherman(idx)) && (
                                        <div className={st.personType}>농업인</div>
                                      )}
                                    {AfterFinalCheckVm.smartFormType() === '어업회사법인' &&
                                      (AfterFinalCheckVm.isShareholder(idx) ||
                                        AfterFinalCheckVm.isFarmerOrFisherman(idx)) && (
                                        <div className={st.personType}>어업인</div>
                                      )}
                                  </div>
                                  <div className={st.address}>{item.address}</div>
                                  {AfterFinalCheckVm.positionInfoDisplay(idx) === '주식회사' && (
                                    <div className={st.positionInfo}>
                                      <span className={st.star}>*</span> 이사가 1명이기 때문에, 등기부에는 대표권 있는
                                      사내이사로 표기됩니다.
                                    </div>
                                  )}
                                  {AfterFinalCheckVm.positionInfoDisplay(idx) === '유한회사' && (
                                    <div className={st.positionInfo}>
                                      <span className={st.star}>*</span> 이사가 1명이기 때문에, 등기부에는 대표권 있는
                                      이사로 표기됩니다.
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.title}>기타정보</div>
                  <div className={st.info}>
                    <div className={st.row}>
                      <div className={st.dName}>사업목적</div>
                      <div className={clsx(st.data, st.businessPurpose)}>
                        <div>
                          <div className={st.purposeBox}>
                            <div className={st.purposeWrapper}>
                              {AfterFinalCheckVm.purposes.map((purpose, idx) => (
                                <span
                                  className={clsx(
                                    st.purpose,
                                    purpose.purposeType === purposeType.RECOMMENDED ? st.recommend : st.direct,
                                  )}
                                  key={idx}
                                >
                                  1. {purpose.content}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className={st.alarmText}>
                            * 총 {AfterFinalCheckVm.totalPurposeCounts}개 ={' '}
                            <span style={{ color: '#006ac3' }}>
                              추천 목적 {AfterFinalCheckVm.recommendedPurposeCounts}개{' '}
                            </span>
                            {AfterFinalCheckVm.directedPurposeCounts > 0 && (
                              <span style={{ color: '#404040' }}>
                                <span style={{ color: '#999fac' }}> + </span>
                                직접입력 목적 {AfterFinalCheckVm.directedPurposeCounts}개
                              </span>
                            )}
                            {` (추가금액 : ${addCommas(AfterFinalCheckVm.totalAdditionalChargeCounts * 5500) || 0}원 / 총 ${AfterFinalCheckVm.totalAdditionalChargeCounts}개 초과)`}
                          </div>
                        </div>
                      </div>
                    </div>
                    {AfterFinalCheckVm.smartFormType() === '주식회사' ||
                    AfterFinalCheckVm.smartFormType() === '농업회사법인' ||
                    AfterFinalCheckVm.smartFormType() === '어업회사법인' ? (
                      <>
                        <div className={st.row}>
                          <div className={st.dName}>스톡옵션</div>
                          <div className={st.data}>{AfterFinalCheckVm.stockOptionYn()}</div>
                        </div>
                        <div className={st.row}>
                          <div className={st.dName}>주식양도제한</div>
                          <div className={st.data}>{AfterFinalCheckVm.shareTransferRestrictionsYn()}</div>
                        </div>
                        <div className={st.row}>
                          <div className={st.dName}>공고방법</div>
                          <div className={st.data}>{AfterFinalCheckVm.announcementMethod}</div>
                        </div>
                        <div className={st.row}>
                          <div className={st.dName}>사회적기업</div>
                          <div className={st.data}>{AfterFinalCheckVm.prospectiveSocialEnterpriseYn()}</div>
                        </div>
                      </>
                    ) : null}
                    <div className={st.row}>
                      <div className={st.dName}>법인인감</div>
                      <div className={st.data}>{AfterFinalCheckVm.corpSeal()}</div>
                    </div>
                    <div className={st.row}>
                      <div className={st.dName}>사용인감</div>
                      <div className={st.data}>{AfterFinalCheckVm.usageSeal()}</div>
                    </div>
                    {AfterFinalCheckVm.canUseYn() && (
                      <div className={st.row}>
                        <div className={st.dName} style={{ lineHeight: '1.2' }}>
                          공동인증서 및
                          <br />
                          금융인증서
                        </div>
                        <div className={st.data}>{AfterFinalCheckVm.canUseYn()}</div>
                      </div>
                    )}
                    <div className={st.row}>
                      <div className={st.dName}>무료사업자등록</div>
                      <div className={st.data}>{AfterFinalCheckVm.freeLicenseRegistrationYn()}</div>
                    </div>
                    {AfterFinalCheckVm.smartFormType() === '농업회사법인' ||
                    AfterFinalCheckVm.smartFormType() === '어업회사법인' ? (
                      <div className={st.row}>
                        <div className={st.dName}>설립통지</div>
                        <div className={st.data}>{AfterFinalCheckVm.noticeOfEstablishmentYn()}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={st.finPrice}>
                  <div className={st.fin}>
                    최종 결제금액<span className={st.explain}>(공과금 + 수수료)</span>
                  </div>
                  <div className={st.price}>
                    {addCommas(AfterFinalCheckVm.finalPayment)}
                    <span className={st.won}>원</span>
                  </div>
                </div>
                <div className={st.submitBtnWrap}>
                  <div className={st.submitExplain}>
                    <div>※ 정보 수정을 원하실 경우, 채팅 상담을 통해 요청해 주세요.</div>
                  </div>
                  <div className={st.sumbitBtn}>
                    <NextBtn onClick={() => AfterFinalCheckVm.submit()} arrow={false}>
                      입력내용 확인 완료하기
                    </NextBtn>
                  </div>
                </div>
              </div>
            )}
          </ContentBox>
        </Question>
      </div>
    </>
  ));
}

export default AfterFinalCheck;
