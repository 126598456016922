class AddStockOptionStepVm {
  스톡옵션Text(num, ventureYn) {
    if (ventureYn) {
      //벤처기업인증
      switch (num) {
        case 1:
          return '(1) 본 회사는 임직원 등에게 발행주식총수의 100분의 50의 범위 내에서 벤처기업육성에 관한 특별조치법에 따라 주식매수선택권을 주주총회 특별결의에 의하여 부여할 수 있다.\n(2) 본 회사는 주식매수선택권의 행사로 내줄 주식 총수의 100분의 20 이내에 해당하는 주식을, 위 법 제16조의3 제1항 제3호에 해당하는 자에게 부여하는 경우, 주주총회의 특별결의로 이사회에서 주식매수선택권을 부여받을 자의 성명이나 명칭, 주식매수선택권을 부여받을 자 각각에 대하여 주식매수선택권의 행사로 내줄 주식의 종류와 수를 정하게 할 수 있다. 이 경우 주식매수선택권을 부여한 후 처음으로 소집되는 주주총회의 승인을 받아야 한다.\n(3) 위 법 제16조의3 제1항 제3호에 해당하는 자에게 주식매수선택권을 부여하는 경우, 그 부여하는 주식 수는 본 회사의 발행주식 총수의 100분의 10을 초과할 수 없다.';
        case 2:
          return '(1) 주식매수선택권의 행사로 발행할 신주식 또는 양도할 자기주식은 기명식 보통주식 또는 정관으로 정하는 종류주식으로 한다.\n(2) 주식매수선택권의 행사가격과 시가와의 차액을 현금 또는 자기주식으로 교부하는 경우, 그 차액의 산정기준이 되는 주식 또한 기명식 보통주식 또는 정관으로 정하는 종류주식으로 한다.';
        case 3:
          return '주식매수선택권을 부여받을 자는 다음 각 호의 어느 하나에 해당하는 자 중 본 회사의 설립, 경영, 기술혁신 등에 기여하거나 기여할 수 있는 자로 한다. 다만 법령에 의하여 주식매수선택권의 부여가 금지되는 자에게는 주식매수선택권을 부여할 수 없다.\n1. 본 회사의 임직원\n2. 본 회사가 인수한 기업(본 회사가 해당기업 발행주식 총수의 100분의 30을 초과하는 주식을 가진 경우에만 해당한다)의 임직원\n3. 벤처기업육성에 관한 특별조치법 제16조의3 제1항 제3호에 해당하는 자';
        case 4:
          return '(1) 주식매수선택권의 행사기간은 그 주식매수선택권을 부여하는 주주총회결의 또는 이사회 결의로 정하되, 그 결의일부터 2년 이상 재임하거나 재직하여야 이를 행사할 수 있다.\n(2) 주식매수선택권을 부여받은 자가 사망하거나 그 밖에 본인의 책임이 아닌 사유로 퇴임 또는 퇴직한 경우, 정해진 행사기간 동안 주식매수선택권을 행사할 수 있고, 주식매수선택권의 행사기한이 퇴임일 또는 퇴직일인 경우  그 퇴임일 또는 퇴직일로부터 3개월 이상의 행사기간을 추가로 부여한다.\n(3) 벤처기업육성에 관한 특별조치법 제16조의3 제1항 3호에 해당하는 자에게 부여된 주식매수선택권은 그 결의가 있는 날 또는 이사회에서 정한 날로부터 2년이 경과하고, 위 법 시행령에서 정한 요건을 만족한 경우 행사할 수 있다.';
        case 5:
          return '다음 각 호에 해당하는 경우에는 이사회의 결의로 주식매수선택권의 부여를 취소할 수 있다.\n(1) 주식매수선택권을 부여받은 자가 본인의 의사에 따라 사임하거나 사직한 경우\n(2) 주식매수선택권을 부여받은 자가 고의 또는 과실로 회사에 중대한 손해를 입힌 경우\n(3) 본 회사의 파산 등으로 주식매수선택권 행사에 응할 수 없는 경우\n(4) 그 밖에 주식매수선택권을 부여받은 자와 체결한 주식매수선택권 부여계약에서 정한 취소사유가 발생한 경우';
      }
    } else {
      //벤처기업인증X
      switch (num) {
        case 1:
          return '본 회사는 임직원에게 발행주식총수의 100분의 10의 범위 내에서 상법에 따라 주식매수선택권을 주주총회 특별결의에 의하여 부여할 수 있다.';
        case 2:
          return '(1) 주식매수선택권의 행사로 발행할 신주식 또는 양도할 자기주식은 기명식 보통주식 또는 정관으로 정하는 종류주식으로 한다.\n(2) 주식매수선택권의 행사가격과 시가와의 차액을 현금 또는 자기주식으로 교부하는 경우, 그 차액의 산정기준이 되는 주식 또한 기명식 보통주식 또는 정관으로 정하는 종류주식으로 한다.';
        case 3:
          return '주식매수선택권을 부여받을 자는 회사의 설립, 경영과 기술혁신 등에 기여하거나 기여할 수 있는 임직원으로 한다. 그러나 법령에 의하여 주식매수선택권의 부여가 금지되는 자에게는 주식매수선택권을 부여할 수 없다.';
        case 4:
          return '주식매수선택권의 행사기간은 그 주식매수선택권을 부여하는 주주총회결의로 정하되, 그 결의일부터 2년 이상 재임 또는 재직하여야 행사할 수 있다.';
        case 5:
          return '다음 각 호에 해당하는 경우에는 이사회의 결의로 주식매수선택권의 부여를 취소할 수 있다.\n(1) 주식매수선택권을 부여받은 자가 본인의 의사에 따라 사임하거나 사직한 경우\n(2) 주식매수선택권을 부여받은 자가 고의 또는 과실로 회사에 중대한 손해를 입힌 경우\n(3) 본 회사의 파산 등으로 주식매수선택권 행사에 응할 수 없는 경우\n(4) 그 밖에 주식매수선택권을 부여받은 자와 체결한 주식매수선택권 부여계약에서 정한 취소사유가 발생한 경우';
      }
    }
  }
}

export default new AddStockOptionStepVm();
