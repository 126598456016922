import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './Certificate.module.scss';
import clsx from 'clsx';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import CertificateVm from './CertificateVm';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import AiChat from '@pages/Establish/AiChat/AiChat';

function Certificate() {
  return useObserver(() => (
    <form onSubmit={(e) => CertificateVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>공동인증서 또는 금융인증서 사용가능 여부를 체크</span>해 주세요.
              </h2>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  name="canUseCertificateYn"
                  value={true}
                  activeBackground
                  checked={CertificateVm.canUseCertificateYn === true}
                  onChange={(e) => CertificateVm.setCanUseCertificateYn(e)}
                >
                  사용 가능합니다. (전자등기로 진행)
                </Radio>
                <div
                  className={
                    CertificateVm.canUseCertificateYn ? clsx(Qst.certificateName, Qst.active) : Qst.certificateName
                  }
                >
                  <ul className={Qst.certificateNameList}>
                    {CertificateVm.koreanName().map((item, idx) => {
                      return <li key={idx}>{item}님</li>;
                    })}
                  </ul>
                </div>
              </li>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  name="canUseCertificateYn"
                  value={false}
                  activeBackground
                  checked={CertificateVm.canUseCertificateYn === false}
                  onChange={(e) => CertificateVm.setCanUseCertificateYn(e)}
                >
                  사용 불가능합니다. <br />
                  (서류등기로 진행, 서울 8.2만 원, 지방 15.9만 원~ 추가)
                </Radio>
              </li>
            </ul>
            <Error
              name="canUseCertificateYn"
              value={CertificateVm.canUseCertificateYn}
              errorCase={{ required: '공동인증서 또는 금융인증서 사용가능 여부를 선택해 주세요.' }}
            />
          </div>
          <div className={Qst.qnaSection}>
            <AiChat />
            <Qna tip="공동인증서 및 금융인증서" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox} style={{ marginTop: '30px' }}>
                <QText>왜 공동인증서 또는 금융인증서가 필요한가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    공동인증서 또는 금융인증서가 있으면, 인감증명서 등 서류준비 없이 빠르게 법인설립이 가능합니다.
                  </Answer>
                  <p className={Qst.explain}>범용 공동인증서도 사용이 가능합니다.</p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>공동인증서와 금융인증서, 2가지 모두 필요한가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    아니오. 공동인증서 또는 금융인증서 중 1개만 있으셔도 전자등기가 가능합니다.
                  </Answer>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>간편인증서로 대체 가능한가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>아니오. 공동인증서 또는 금융인증서만 가능합니다.</Answer>
                  <p className={Qst.explain}>
                    대법원이 운영하는 인터넷등기소에서는 공동인증서 또는 금융인증서만 지원하고 있기 때문에, 간편인증서는
                    사용이 불가능합니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>
                  공동인증서 또는 금융인증서 사용이 불가능한 {CertificateVm.name()}, 임원이 있다면 어떻게 해야 하나요?
                </QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    불가능한 {CertificateVm.name()}, 임원이 1명이라도 있다면, 서류등기 방식으로 진행해드립니다.
                  </Answer>
                  <p className={Qst.explain}>
                    서류등기로 진행하는 경우, 3개월 안에 발급받은 인감증명서 원본, 주민등록초(등)본 원본, 인감도장
                    준비가 필요하고, 시간은 3일 정도 더 소요됩니다. 또한 추가세금을 포함하여 서울 8.2만 원, 지방 15.9만
                    원~ 의 추가비용이 발생합니다.
                    <br />
                    등기소에서 일부 임원, {CertificateVm.name()}는 서류를 준비하고, 일부는 공동인증서 또는 금융인증서를
                    준비하는 방식은 지원하지 않습니다. 따라서 공동인증서 또는 금융인증서 사용이 불가능한{' '}
                    {CertificateVm.name()}, 임원이 1명이라도 있다면, 모든 임원, {CertificateVm.name()}가 서류를
                    준비해주셔야 합니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>공동인증서 또는 금융인증서는 어떻게 사용하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    최종단계(1~3일 후)에서 {CertificateVm.name()} 임원분들이 각자 집에서 인터넷등기소 모바일 어플 또는
                    사이트에 접속해 비밀번호를 넣어 전자서명해주시면 됩니다.
                  </Answer>
                  <p className={Qst.explain}>
                    헬프미에 공동인증서 또는 금융인증서를 보내주시거나, 모두 한 곳에 모일 필요가 없습니다. 해외에서도
                    공동인증서 또는 금융인증서 서명이 가능합니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>모바일 어플이나 맥 컴퓨터에서도 공동인증서 또는 금융인증서 사용이 가능한가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>네. 가능합니다.</Answer>
                  <p className={Qst.explain}>
                    기존에는 모바일 어플이나 맥 컴퓨터에서는 서명 기능을 제공하지 않았으나, 25년 2월 인터넷등기소
                    개편으로 가능하게 되었습니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>공동인증서 또는 금융인증서는 어떻게 발급받나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    공동인증서와 금융인증서 모두 시중은행에서 무료로 발급받을 수 있습니다.
                  </Answer>
                  <p className={Qst.explain}>
                    공동인증서 또는 금융인증서가 없는 경우, 시중은행에 방문하시거나 온라인에서 새로 발급받아 진행하는
                    편을 추천드립니다. 서류등기로 진행하는 경우 비용이 더 들뿐만 아니라, 주민센터를 방문해서 인감증명서
                    원본을 발급받아야 합니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>공동인증서 또는 금융인증서 사용이 가능한지 불확실한데, 어떻게 답변하면 되나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    지연을 피하기 위해서는 미리 사용가능여부를 확인 후 답변해 주세요.
                  </Answer>
                  <p className={Qst.explain}>
                    사용 여부에 따라 작성하는 서류종류가 달라집니다. 지금 인증서 사용이 가능한 것으로 체크하셨다가
                    나중에 변경될 경우, 시간이 지연될 수 있습니다.
                  </p>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}

export default Certificate;
